import type { 
  TagsGroup,
  TagFilter,
} from './Other.interface'


export function getTagsByType(tags: TagsGroup, selected: string[], type: string): string[] {
  const query: string[] = [];
  for (const s of selected) {
    const params = s.split(':');
    if (s.indexOf(type) === 0) {
      if (type === 'kw') {
        query.push(params[2]);
      } else {
        query.push(params[1]);
      }
    }
  }
  return query;
}

export function getTagsWeek(tags: TagsGroup, selected: string[]): string[] {
  return getTagsByType(tags, selected, 'week');
}

export function getTagsBrandSeed(tags: TagsGroup, selected: string[]): string[] {
  return getTagsByType(tags, selected, 'bsd');
}

export function getTagsSeed(tags: TagsGroup, selected: string[]): string[] {
  return getTagsByType(tags, selected, 'sd');
}

export function getTagsBrandNutrient(tags: TagsGroup, selected: string[]): string[] {
  return getTagsByType(tags, selected, 'bnt');
}


export function getTagsNutrient(tags: TagsGroup, selected: string[]): string[] {
  return getTagsByType(tags, selected, 'nt');
}


export function getTagsBrandLamp(tags: TagsGroup, selected: string[]): string[] {
  return getTagsByType(tags, selected, 'blm');
}


export function getTagsLamp(tags: TagsGroup, selected: string[]): string[] {
  return getTagsByType(tags, selected, 'lm');
}


export function getTagsBrandTent(tags: TagsGroup, selected: string[]): string[] {
  return getTagsByType(tags, selected, 'btn');
}


export function getTagsTent(tags: TagsGroup, selected: string[]): string[] {
  return getTagsByType(tags, selected, 'tn');
}


export function getTagsGrower(tags: TagsGroup, selected: string[]): string[] {
  return getTagsByType(tags, selected, 'us');
}


export function getTagsKeyword(
  tags: TagsGroup,
  selected: string[] = [],
  permanent: string[] = []
): string[] {
  const all = [...selected, ...permanent];
  return getTagsByType(tags, all, 'kw');
}


export function getTagsKeys(tags: TagsGroup): string {
  const query: string[] = [];
  for (const tagKey in tags) {
    query.push(tagKey);
  }
  return query.join(',');
}


export function getTagsSimple(
  tags: TagsGroup,
  selected: string[] = [],
  permanent: string[] = []
): string {
  const all = [...selected, ...permanent];
  return all.length ? all.join(',') : '';
}


export function getTagByPath(tags: TagsGroup, path: string): string {
  for (const tagKey in tags) {
    const tagData = tags[tagKey];
    if (tagData.path === path) {
      return tagKey;
    }
    if (tagData.childs) {
      for (const childKey in tagData.childs) {
        if (tagData.childs[childKey].path === path) {
          return childKey;
        }
      }
    }
  }
  return '';
}


export function getFilterByTag(tags: TagsGroup, tag: string): TagFilter | string {
  if (tags[tag]?.filter) {
    return tags[tag].filter;
  }
  for (const key in tags) {
    const group = tags[key];
    if (group?.childs && group.childs[tag]?.filter) {
      return group.childs[tag].filter;
    }
  }
  return tag;
}


export function getPathByTag(tags: TagsGroup, tag: string): string {
  if (tags[tag]?.path) {
    return tags[tag].path;
  }
  for (const key in tags) {
    const group = tags[key];
    if (group?.childs && group.childs[tag]?.path) {
      return group.childs[tag].path;
    }
  }
  return '';
}

export function getTagsSimpleTrKey(tags: TagsGroup, selected: string[]): string[] {
  const result: string[] = [];
  for (const tagKey in tags) {
    const tagData = tags[tagKey];
    if (tagKey.startsWith('kw:')) {
      continue;
    }
    if (tagKey.includes(':')) {
      continue;
    }
    if (selected.includes(tagKey) && tagData.trkey) {
      result.push(tagData.trkey);
    }
    if (tagData.childs) {
      for (const childKey in tagData.childs) {
        if (selected.includes(childKey) && tagData.childs[childKey].trkey) {
          result.push(tagData.childs[childKey].trkey);
        }
      }
    }
  }
  return result;
}





// getTagsByType: function(tags, selected, type){

//   var query = [];
//   for(var s of selected){
//     var params = s.split(':');
//     if(s.indexOf(type) == 0){
//       if(type == 'kw')
//         query.push(params[2]);
//       else
//         query.push(params[1]);
//     }
//   }
//   return query;
// },
// getTagsWeek: function(tags, selected){    
//   return this.getTagsByType(tags, selected, 'week');
// },
// getTagsBrandSeed: function(tags, selected){    
//   return this.getTagsByType(tags, selected, 'bsd');
// },
// getTagsSeed: function(tags, selected){    
//   return this.getTagsByType(tags, selected, 'sd');
// },
// getTagsBrandNutrient: function(tags, selected){    
//   return this.getTagsByType(tags, selected, 'bnt');
// },
// getTagsNutrient: function(tags, selected){    
//   return this.getTagsByType(tags, selected, 'nt');
// },
// getTagsBrandLamp: function(tags, selected){    
//   return this.getTagsByType(tags, selected, 'blm');
// },
// getTagsLamp: function(tags, selected){    
//   return this.getTagsByType(tags, selected, 'lm');
// },
// getTagsBrandTent: function(tags, selected){    
//   return this.getTagsByType(tags, selected, 'btn');
// },
// getTagsTent: function(tags, selected){    
//   return this.getTagsByType(tags, selected, 'tn');
// },
// getTagsGrower: function(tags, selected){    
//   return this.getTagsByType(tags, selected, 'us');
// },
// getTagsKeyword: function(tags, selected, permanent){
//   // console.log('getTagsKeyword');
//   // console.log(tags);
//   // console.log(selected);

//   // merge selected and permanent

//   var all = [];

//   if(selected?.length){
//     for(var s of selected){
//       all.push(s);
//     }
//   }

//   if(permanent?.length){
//     for(var s of permanent){
//       all.push(s);
//     }
//   }
//   return this.getTagsByType(tags, all, 'kw'); 
// },
// getTagsKeys: function(tags){
//   var query = [];
//   for(var tags_key in tags){
//     query.push(tags_key);
//   }
//   return query.join(',');
// },
// getTagsSimple: function(tags, selected, permanent){

//   var all = [];

//   if(selected?.length){
//     for(var s of selected){
//       all.push(s);
//     }
//   }

//   if(permanent?.length){
//     for(var s of permanent){
//       all.push(s);
//     }
//   }

//   return all ? all.join(',') : '';

// },
// getTagByPath: function(tags, path){
//   for(var tags_key in tags){
//     var s = tags[tags_key];
//     if(s.path == path){
//       return tags_key;
//     }
//     if(s.childs){
//       for(var c in s.childs){
//         if(s.childs[c].path == path){
//           return c;
//         }
//       }
//     }
//   }
//   return '';
// },
// getFilterByTag: function(tags, tag) {

//   if (tags[tag]?.filter) {
//     return tags[tag].filter;
//   }

//   // tags is object
//   for (const key in tags) {
//     const group = tags[key];
//     if (group && group.childs && group.childs[tag]?.filter) {
//       return group.childs[tag].filter;
//     }
//   }

//   return tag;
// },
// getPathByTag: function(tags, tag) {

//   if (tags[tag]?.path) {
//     return tags[tag].path;
//   }

//   // tags is object
//   for (const key in tags) {
//     const group = tags[key];
//     if (group && group.childs && group.childs[tag]?.path) {
//       return group.childs[tag].path;
//     }
//   }

//   return '';
// },
// getTagsSimpleTrKey: function(tags, selected){
//   var query = [];
//   for(var tags_key in tags){
//     var s = tags[tags_key];
//     if(tags_key.indexOf('kw:') == 0){
//       continue;
//     }
//     if(tags_key.indexOf(':') >= 0){
//       continue;
//     }

//     if(selected.indexOf(tags_key) != -1){
//       query.push(tags[tags_key].trkey);
//     }
//     if(s.childs){
//       for(var c in s.childs){
//         if(selected.indexOf(c) != -1){
//           query.push(s.childs[c].trkey);
//         }
//       }
//     }
//   }
//   //console.log('getTagsSimpleTR', query);
//   return query;
// }




